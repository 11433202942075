<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-right">
        {{ sessionTitle }}
      </p>
      <b-table
        :data="schoolFeesSchoolClassReport"
        :paginated="isPaginated"
        hoverable
      >
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ schoolFeesSchoolClassReport.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Name" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column label="Total Revenue" v-slot="props">{{
          convertToCurrency(props.row.total, '₦')
        }}</b-table-column>

        <b-table-column label="Paid" v-slot="props">{{
          convertToCurrency(props.row.paid, '₦')
        }}</b-table-column>

        <b-table-column label="Balance" v-slot="props">{{
          convertToCurrency(props.row.balance, '₦')
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            :show-update="false"
            :show-delete="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import { toCurrency } from '@/assets/js/currency_formatter'

export default {
  data() {
    return {
      schoolId: null,
      termId: null,
      pageTitle: 'School Fees School Class Report',
      schoolFeesSchoolClassReport: [],
      isPaginated: false,
      sessionTitle: '',
      classGroupId: null,
    }
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.classGroupId = parseInt(this.$route.params.class_group_id)

    this.$store.commit('setPageTitle', this.pageTitle)

    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.$route.params.school_id}/fee_management`,
      },
      {
        name: 'School Fees Class Group Report',
        route: `/school/${this.schoolId}/school_fees_class_group_report`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.sessionTitle = term.session_title

      this.$apollo.addSmartQuery('schoolFeesSchoolClassReport', {
        query: gql`
          query SchoolFeesSchoolClassReportQuery(
            $classGroupId: ID!
            $termId: ID!
          ) {
            schoolFeesSchoolClassReport(
              classGroupId: $classGroupId
              termId: $termId
            )
          }
        `,
        error(error) {
          console.log(error.message)
        },
        variables: {
          classGroupId: this.classGroupId,
          termId: this.termId,
        },
      })

      this.$apollo.queries.schoolFeesSchoolClassReport.refetch()
    })
  },
  methods: {
    convertToCurrency(value, symbol) {
      return toCurrency(value, symbol)
    },
    initializeInfo(school_class) {
      this.$router.push(
        `/school/${this.schoolId}/school_fees_students_report/class_group/${this.classGroupId}/school_class/${school_class.id}`
      )
    },
  },
}
</script>
